import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import DocSidebar from "../components/docSidebar"

import apmIcon from '../images/apm-icon.svg'
import exceptionsIcon from '../images/exceptions-icon.svg'
import logsIcon from '../images/logs-icon.svg'
import phpLogo from '../images/php-logo.svg'
import laravelLogo from '../images/laravel-logo.png'

const Blog = () => {

    return (
        <Layout 
            title="Documentation"
            description=""
        >
            <div className="container mt-12 mb-12">
                <div className="flex">
                    <div className="mr-32 flex flex-col">
                        <DocSidebar />
                    </div>

                    <div className="flex-grow">
                        <div className="mb-8 px-4 py-3 bg-orange-100 border border-orange-300 rounded text-orange-800">Documentation is in progress and subject to change.</div>

                        <h1 className="mb-4">Get started with tail.dev</h1>


                        <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mb-24" to="/documentation/quickstart">
                            <div className="flex items-center">
                                <div className="mr-4 text-gray-400">
                                    <i className="far fa-clock fa-2x" />
                                </div>
                                <div>
                                    <h4 className="mb-0">Quickstart</h4>
                                    <p className="text-sm text-gray-500">Start here to get up and running right away.</p>
                                </div>
                            </div>
                        </Link>

                        <h3 className="mb-1">Understand the platform</h3>
                        <p className="mb-8">These guides provide key concepts and topology for how tail.dev works.</p>
                        <div className="flex mb-24">
                            <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mr-6" to="/documentation/concepts/apm">
                                <div className="flex items-center">
                                    <div className="mr-4">
                                        <img src={apmIcon} alt="APM icon" style={{width: '30px'}} />
                                    </div>
                                    <div>
                                        <h4 className="mb-0">APM</h4>
                                    </div>
                                </div>
                            </Link>

                            <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mr-6" to="/documentation/concepts/errors">
                                <div className="flex items-center">
                                    <div className="mr-4">
                                        <img src={exceptionsIcon} alt="Errors icon" style={{width: '30px'}} />
                                    </div>
                                    <div>
                                        <h4 className="mb-0">Errors</h4>
                                    </div>
                                </div>
                            </Link>

                            <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75" to="/documentation/concepts/logs">
                                <div className="flex items-center">
                                    <div className="mr-4">
                                        <img src={logsIcon} alt="Logs icon" style={{width: '30px'}} />
                                    </div>
                                    <div>
                                        <h4 className="mb-0">Logs</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <h3>Install and setup</h3>
                        <div className="flex mb-24">
                            <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mr-6 w-1/4" to="/documentation/php/get-started">
                                <div className="flex items-center justify-center">
                                    <div className="mr-2">
                                        <img src={phpLogo} alt="PHP logo" style={{width: '30px'}} />
                                    </div>
                                    <div>
                                        <h4 className="mb-0">PHP</h4>
                                    </div>
                                </div>
                            </Link>

                            <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 w-1/4" to="/documentation/laravel/get-started">
                                <div className="flex items-center justify-center">
                                    <div className="mr-2">
                                        <img src={laravelLogo} alt="Laravel logo" style={{width: '30px'}} />
                                    </div>
                                    <div>
                                        <h4 className="mb-0">Laravel</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <h3>Setup and manage your organization</h3>
                        <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mb-8" to="/documentation/organizations">
                            <div className="flex items-center">
                                <div className="mr-4 text-gray-400">
                                    <i className="far fa-building fa-2x" />
                                </div>
                                <div>
                                    <h4 className="mb-0">Organizations</h4>
                                    {/* <p className="text-sm text-gray-500">What is this quickstart and why its amazing.</p> */}
                                </div>
                            </div>
                        </Link>

                        <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mb-8" to="/documentation/auth-tokens">
                            <div className="flex items-center">
                                <div className="mr-4 text-gray-400">
                                    <i className="fas fa-lock fa-2x" />
                                </div>
                                <div>
                                    <h4 className="mb-0">Auth tokens</h4>
                                    {/* <p className="text-sm text-gray-500">What is this quickstart and why its amazing.</p> */}
                                </div>
                            </div>
                        </Link>
                        
                        <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mb-8" to="/documentation/users">
                            <div className="flex items-center">
                                <div className="mr-4 text-gray-400">
                                    <i className="fas fa-user fa-2x" />
                                </div>
                                <div>
                                    <h4 className="mb-0">Users</h4>
                                    {/* <p className="text-sm text-gray-500">What is this quickstart and why its amazing.</p> */}
                                </div>
                            </div>
                        </Link>

                        <Link className="bg-white block px-5 py-4 rounded-sm shadow hover:opacity-75 mb-8" to="/documentation/billing">
                            <div className="flex items-center">
                                <div className="mr-4 text-gray-400">
                                    <i className="fas fa-dollar-sign fa-2x" />
                                </div>
                                <div>
                                    <h4 className="mb-0">Billing</h4>
                                    {/* <p className="text-sm text-gray-500">What is this quickstart and why its amazing.</p> */}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>{/* flex */}
            </div>
        </Layout>
    )
}

export default Blog
