import React from 'react'
import { Link } from "gatsby"

const DocSidebar = () => {

    const path = typeof window !== 'undefined' ? window.location.pathname : ''
    const openPhpSubmenu = path.match(/documentation\/php\/.*/)
    const openLaravelSubmenu = path.match(/documentation\/laravel\/.*/)

    return (<>
        <span className="text-sm font-bold text-gray-400 mb-2">Get started</span>
        <Link to="/documentation" className="text-gray-800 mb-1" activeClassName="text-blue-400">Home</Link>
        <Link to="/documentation/quickstart" className="text-gray-800 mb-1" activeClassName="text-blue-400">Quickstart</Link>

        <span className="text-sm font-bold text-gray-400 mb-2 mt-8">Key concepts</span>
        <Link to="/documentation/concepts/apm" className="text-gray-800 mb-1" activeClassName="text-blue-400">APM</Link>
        <Link to="/documentation/concepts/errors" className="text-gray-800 mb-1" activeClassName="text-blue-400">Errors</Link>
        <Link to="/documentation/concepts/logs" className="text-gray-800 mb-1" activeClassName="text-blue-400">Logs</Link>

        <span className="text-sm font-bold text-gray-400 mb-2 mt-8">Integration</span>
        <Link to="/documentation/php/get-started" className="text-gray-800 mb-1">PHP</Link>
        { openPhpSubmenu && 
            <ul className="mb-2 text-sm">
                <li><Link to="/documentation/php/get-started" className="text-gray-800 mb-1" activeClassName="text-blue-400">Get started</Link></li>
                <li><Link to="/documentation/php/transactions" className="text-gray-800 mb-1" activeClassName="text-blue-400">Transactions</Link></li>
                <li><Link to="/documentation/php/spans" className="text-gray-800 mb-1" activeClassName="text-blue-400">Spans</Link></li>
            </ul>
        }

        <Link to="/documentation/laravel/get-started" className="text-gray-800 mb-1">Laravel</Link>
        { openLaravelSubmenu &&
            <ul className="mb-2 text-sm">
                <li><Link to="/documentation/laravel/get-started" className="text-gray-800 mb-1" activeClassName="text-blue-400">Get started</Link></li>
                <li><Link to="/documentation/laravel/transactions" className="text-gray-800 mb-1" activeClassName="text-blue-400">Transactions</Link></li>
                <li><Link to="/documentation/laravel/spans" className="text-gray-800 mb-1" activeClassName="text-blue-400">Spans</Link></li>
            </ul>
        }


        <span className="text-sm font-bold text-gray-400 mb-2 mt-8">Manage</span>
        <Link to="/documentation/organizations" className="text-gray-800 mb-1" activeClassName="text-blue-400">Organizations</Link>
        <Link to="/documentation/auth-tokens" className="text-gray-800 mb-1" activeClassName="text-blue-400">Auth tokens</Link>
        <Link to="/documentation/users" className="text-gray-800 mb-1" activeClassName="text-blue-400">Users</Link>
        <Link to="/documentation/billing" className="text-gray-800 mb-1" activeClassName="text-blue-400">Billing</Link>
    </>)
}

export default DocSidebar